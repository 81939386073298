/* loader css start */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.loader-container {
  position: relative; /* Ensure the loader stays centered */
}

.add-button {
  background: #2e37a4;
  border: 1px solid #2e37a4;
  margin-right: 15px;
  color: #ffff;
}

.add-button:hover {
  background: #00d3c7;
  border: 1px solid #00d3c7;
}

/* loader css end */

.meditablehide,
.recomendedhide,
.receipthide {
  display: none;
}
@media print {
  .ck-sticky-panel__content {
    display: none !important;
  }
  .meditableunhide {
    display: none !important;
  }
  .meditablehide {
    display: block !important;
  }
}

.formsecondckeditor .ck-sticky-panel__content {
  display: none;
}
.dischargeformsecondckeditor .ck-editor__editable {
  border: none !important;
}
/* css for dropdown height and there border color so it can match with input height and there border color*/
select.form-select {
  border: 2px solid rgba(46, 55, 164, 0.1);
  min-height: 45px;
}

.ipdformtable {
  overflow: scroll !important;
}
.ipdformtablehead {
  height: 500px !important;
}

.formsecondckeditor {
  /* width: 50%;  */
  white-space: normal; /* Allows text to wrap properly */
  overflow-wrap: break-word; /* Ensures long words break to the next line */
  word-wrap: break-word; /* For backward compatibility */
  overflow: visible; /* Ensures text is not hidden */
}

/* dashbord icon size */
.dash-boxs.comman-flex-center img {
  width: 80px;
  height: 50px;
  cursor: pointer;
}
.dash-widget{
  cursor: pointer;
}
#balance-btn{
  background-color: rgb(222, 103, 18);
}

/* Hide elements when printing */
@media print {
  .no-print {
    display: none !important;
  }
}

.ant-table-thead {
  white-space: nowrap;
}
/* dashbord icon size */
.dash-boxs.comman-flex-center img {
  width: 80px;
  height: 50px;
}


/* css for report dashboard dropdown */
.nav-item.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  list-style: none;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 300px;
}

.dropdown-menu.show {
  display: block;
}

.nav-item .dropdown-menu li {
  padding: 5px 10px;
}

.nav-item .dropdown-menu li a {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
}

.nav-item .dropdown-menu li a i {
  margin-right: 8px;
}

.nav-item .dropdown-menu li a:hover {
  background-color: #f0f0f0;
}



